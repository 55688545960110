<template>
  <CustomMegaMenu visible class="mega-menu-mobile">
    <div v-for="item in firtsLevelItems(menu)" :key="item.uid">
      <CustomMegaMenuColumn :title="item.label" :link="item.link">
        <div class="menu-lv2">
          <div
            v-if="
              menuImagesContent &&
              menuImagesContent.active == true &&
              menuImagesContent.data
            "
            class="menu-lv2__slider"
          >
            <div
              v-for="(slide, index) in menuImagesContent.data"
              :key="`menu-slider-${index}`"
              class="menu-slider__item"
            >
              <div
                v-if="
                  validItemAndSlideCarouselData(
                    item.label,
                    index,
                    menuImagesContent.data
                  ) &&
                  slide.data &&
                  slide.data.length > 0 &&
                  getValidImages(slide.data).length > 0
                "
              >
                <VueSlickCarousel
                  v-bind="settings"
                  class="slick-home-collection"
                  @init="carouselIndex = 0"
                  @beforeChange="(oldIdx, newIdx) => (carouselIndex = newIdx)"
                >
                  <div
                    v-for="(imageData, idx) in getValidImages(slide.data)"
                    :key="`menu-img-${idx}`"
                  >
                    <nuxt-link :to="fixUrlForCurrentLocale(imageData.link.url)">
                      <div class="menu-slider__item__image">
                        <nuxt-img
                          :src="imageData.image.mobile"
                          preload
                          loading="lazy"
                          width="230"
                          height="230"
                        />
                        <div class="menu-slider__item__image__text">
                          {{ imageData.title }}
                        </div>
                      </div>
                    </nuxt-link>
                  </div>
                </VueSlickCarousel>
                <div class="slick-home-collection-bullets">
                  <SfBullets
                    :total="getValidImages(slide.data).length"
                    :current="carouselIndex"
                  >
                    <template #active>
                      <ProductBullets :active="true" />
                    </template>
                    <template #inactive>
                      <ProductBullets :active="false" />
                    </template>
                  </SfBullets>
                </div>
              </div>
            </div>
          </div>
          <div v-for="child in secondLevelItems(item)" :key="child.uid">
            <CustomMegaMenuColumn :title="child.label" :link="child.link">
              <div class="menu-lv3">
                <div
                  v-for="(childLv2, childLv2Index) in child.children"
                  :key="childLv2.uid"
                  class="menu-lv3__links"
                >
                  <div
                    v-if="hasDivider(childLv2.label)"
                    class="menu-lv3__links__divider"
                  />
                  <span
                    class="sf-menu-item__label"
                    @click="goTo(childLv2.link)"
                  >
                    {{ childLv2.label }}
                  </span>
                  <div
                    v-if="
                      hasDivider(childLv2.label, true) &&
                      !hasDivider(child.children[childLv2Index + 1].label)
                    "
                    class="menu-lv3__links__divider"
                  />
                </div>
              </div>
            </CustomMegaMenuColumn>
          </div>
        </div>
        <SfMenuItem
          v-for="child in secondLevelItems(item, false)"
          :key="child.uid"
          :label="child.label"
          icon=""
          :link="fixUrlForCurrentLocale(child.link)"
          class="sf-mega-menu-column__header user-links"
        />
      </CustomMegaMenuColumn>
    </div>
    <SfMenuItem
      v-for="item in firtsLevelItems(menu, false)"
      :key="item.uid"
      :label="item.label"
      icon=""
      :link="fixUrlForCurrentLocale(item.link)"
      class="sf-mega-menu-column__header user-links"
    />
    <div class="mega-menu-mobile__bottom-links">
      <div
        class="my-account link"
        @click="
          handleAccountClick();
          openMenuLink();
        "
      >
        <div class="icon">
          <component is="MyAccountIcon" />
        </div>
        <div class="label">
          {{ userName ? `${$t('Hello, ')} ${userName}` : $t('Login') }}
        </div>
      </div>
      <nuxt-link
        :to="fixUrlForCurrentLocale('/cart')"
        class="m-link my-cart link"
      >
        <div class="icon">
          <component is="BagIcon" />
        </div>
        <div class="label">
          {{ $t('Cart') }}
        </div>
      </nuxt-link>
      <nuxt-link
        :to="fixUrlForCurrentLocale('/wishlist')"
        class="m-link wishlist link"
        @click="
          handleWishlistClick();
          openMenuLink();
        "
      >
        <div class="icon">
          <component is="HearthIcon" />
        </div>
        <div class="label">
          {{ $t('Wishlist') }}
        </div>
      </nuxt-link>
      <nuxt-link
        v-if="supportLink"
        class="support link m-link"
        :to="fixUrlForCurrentLocale(supportLink['link-url'])"
      >
        <div class="icon">
          <component is="PhoneIcon" />
        </div>
        <div class="label">
          {{ supportLink['link-label'] }}
        </div>
      </nuxt-link>
      <nuxt-link
        :to="
          fixUrlForCurrentLocale(
            locale.includes('it') ? '/contatti' : '/contacts'
          )
        "
        class="m-link support link"
        v-else
      >
        <div class="icon">
          <component is="PhoneIcon" />
        </div>
        <div class="label">
          {{ $t('Support') }}
        </div>
      </nuxt-link>
    </div>
  </CustomMegaMenu>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  useContext,
  useFetch,
  useRouter,
} from '@nuxtjs/composition-api';
import { SfMenuItem, SfBullets } from '@storefront-ui/vue';
import { useUser } from '@gemini-vsf/composables';
import { useUiState, useWindow } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomMegaMenu from './CustomMegaMenu.vue';
import {
  MyAccountIcon,
  BagIcon,
  HearthIcon,
  PhoneIcon,
  LanguageIcon,
} from '~/components/General/icons';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import ProductBullets from '~/components/Product/ProductCardComponents/ProductBullets.vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'MegaMenu',
  components: {
    ProductBullets,
    CustomMegaMenu,
    SfMenuItem,
    MyAccountIcon,
    BagIcon,
    HearthIcon,
    PhoneIcon,
    LanguageIcon,
    VueSlickCarousel,
    SfBullets,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    menuImagesContent: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const router = useRouter();
    const { isAuthenticated, user } = useUser();
    const { app } = useContext();
    const { toggleLoginModal, toggleMobileMenu } = useUiState();
    const { handleHtmlClass } = useWindow();

    const userName = computed(() =>
      isAuthenticated.value ? user.value?.firstname : ''
    );

    const openMenuLink = () => {
      handleHtmlClass('removeClass', 'open_menu_html');
      toggleMobileMenu();
    };

    const getMegaMenuItems = (menu) => {
      return 'items' in menu ? menu.items : menu.children;
    };

    const hasChildren = (menuItem) => Boolean(menuItem?.children.length > 0);

    const firtsLevelItems = (menu, hasChildrens = true) =>
      menu.items.filter((item) => hasChildren(item) === hasChildrens);

    const secondLevelItems = (menu, hasChildrens = true) => {
      return menu.children.filter((item) => hasChildren(item) === hasChildrens);
    };

    const getItems = (menuItem, type) => {
      if (!hasChildren(menuItem)) {
        return false;
      }
      const items = [];
      menuItem.children.forEach((child) => {
        if (type === 'items' && hasChildren(child)) {
          items.push(child);
        } else if (type === 'featured' && !hasChildren(child)) {
          items.push(child);
        }
      });
      return items.length > 0 ? items : false;
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'my-account' }));
      } else {
        toggleLoginModal();
      }
    };

    const handleWishlistClick = async () => {
      await router.push(app.localeRoute({ name: 'wishlist' }));
    };

    const handleCartClick = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const { locale } = useI18n();
    const supportLink = ref();
    useFetch(async () => {
      supportLink.value = {
        'link-label': locale.includes('it') ? 'Contattaci' : 'Contact us',
        'link-url': locale.includes('it') ? '/contatti' : '/contacts',
      };
    });

    const hasDivider = (item, bottom = false) => {
      if (!item) return false;
      const itemsWithBottomLink = ['saldi', 'sale'];
      const itemsWithTopLink = ['vedi tutti', 'view all'];
      return bottom
        ? itemsWithBottomLink.includes(item.toLowerCase())
        : itemsWithTopLink.includes(item.toLowerCase());
    };

    const getValidImages = (images) =>
      images.filter(
        (image) => image?.active && image.link?.url && image.image?.mobile
      );

    const goTo = (link) => {
      router.push(app.localePath(link));
    };

    const possibleMenuCarousels = ref([
      {
        code: 'woman',
        labels: ['Women', 'Woman', 'Donna'],
      },
      {
        code: 'man',
        labels: ['Men', 'Man', 'Uomo'],
      },
      {
        code: 'kids',
        labels: ['Kids', 'Bambino'],
      },
      {
        code: 'home',
        labels: ['Home', 'Casa'],
      },
    ]);
    const validItemAndSlideCarouselData = (label, index, data) =>
      possibleMenuCarousels.value.find(
        (p) =>
          // eslint-disable-next-line eqeqeq
          index == p.code &&
          p.labels.includes(label) &&
          // eslint-disable-next-line sonarjs/no-redundant-boolean, eqeqeq
          data?.[index]?.active == true
      );
    const carouselIndex = ref(0);

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      isAuthenticated,
      getMegaMenuItems,
      hasChildren,
      getItems,
      getValidImages,
      handleAccountClick,
      handleWishlistClick,
      handleCartClick,
      supportLink,
      locale,
      userName,
      firtsLevelItems,
      secondLevelItems,
      openMenuLink,
      hasDivider,
      goTo,
      carouselIndex,
      possibleMenuCarousels,
      validItemAndSlideCarouselData,
      fixUrlForCurrentLocale,
      settings: {
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        variableWidth: true,
      },
    };
  },
});
</script>

<style lang="scss">
.sf-mega-menu-column__content {
  padding-bottom: 13rem;
  left: 0;
}
.mega-menu-mobile {
  .m-link {
    display: flex;
    align-items: center;
    color: var(--c-text);
    svg {
      margin-right: 12px;
    }
  }
  &:not(.is-active) {
    background-color: var(--c-super-light-grey);
    .custom-mega-menu__container {
      height: calc(100vh - (var(--header-height) + var(--topbar-height)));
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .sf-mega-menu-column__header,
  .sf-mega-menu__bar {
    height: 4.125rem;
    border-bottom: 1px solid var(--c-grey);
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    background-color: var(--c-white);
    &:hover {
      --menu-item-label-color: inherit;
    }
    &.user-links {
      width: calc(100vw - 1.25rem);
      margin-left: 0.625rem;
      background-color: var(--c-super-light-grey);
    }
  }
  .sf-mega-menu-column {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sf-menu-item {
    &:hover {
      --menu-item-label-color: inherit;
    }
    &__label {
      @include paragraph-regular;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--c-black);
    }
  }
  .menu-lv2 {
    color: var(--c-black);
    &__featured {
      padding: 20px 1.563rem;
      border-bottom: 1px solid var(--c-grey);
    }
    &__links {
      padding: 10px 0;
      @include link-small;
      letter-spacing: 0.0437rem;
      text-transform: uppercase;
      font-weight: bold;
      &__label {
        margin-left: 0.375rem;
        color: var(--c-grey-2);
        letter-spacing: 0.05rem;
      }
    }
    .sf-mega-menu-column {
      width: calc(100vw - 0.625rem);
      padding-right: 0;
      .sf-mega-menu-column__header {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
      }
    }
    .sf-menu-item__label {
      @include link-small;
      letter-spacing: 0.0437rem;
      text-transform: uppercase;
      font-weight: bold;
    }
    .menu-lv3 {
      padding: 1.25rem;
      &__links {
        margin: 0.9375rem 0;
        .sf-menu-item__label {
          text-transform: uppercase;
          @include link-small;
          letter-spacing: 0.0437rem;
          font-weight: normal;
        }
        &__divider {
          margin: 1.25rem 0;
          border-bottom: var(--grey-border);
        }
      }
    }
    &__slider {
      .slick-home-collection {
        .menu-slider__item {
          &__image {
            position: relative;
            &__text {
              @include desktop-h4;
              text-transform: uppercase;
              color: #ffffff;
              text-align: center;
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
        }
        &-bullets {
          padding: 0 1.25rem;
        }
      }
    }
  }
  .sf-mega-menu {
    &__content {
      margin: 0;
    }
    &__bar {
      background-color: var(--c-white);
      padding-right: 1.25rem;
      > div:first-child {
        width: 0;
        .sf-bar__icon {
          width: 0.875rem;
          .sf-icon {
            left: 0.3125rem;
            width: 1.5625rem;
            height: 1.5625rem;
          }
        }
      }
      .sf-bar__title {
        color: var(--c-black);
        @include desktop-h4;
        text-transform: uppercase;
      }
    }
    &__menu {
      > div {
        width: 100vw;
      }
    }
  }
  .mega-menu-mobile__bottom-links {
    margin: 2.1875rem 1.25rem;

    div {
      display: flex;
      gap: 0.625rem;
      min-width: 1.0625rem;
    }
    .link {
      text-transform: uppercase;
      margin-bottom: 1.25rem;
      @include link-small;
      letter-spacing: 0.0437rem;

      &.support {
        margin-bottom: 0;
      }
    }
  }
}
</style>
