const mapDefaultSortingCategories = new Map();
mapDefaultSortingCategories.set('65129b7257ad882b2177ae27', 1); // MUST-HAVE DI STAGIONE PER LEI
mapDefaultSortingCategories.set('65143d2ae58f1fcc1758486b', 1); // MUST-HAVE DI STAGIONE PER LUI
mapDefaultSortingCategories.set('6512b08557ad882b2177ae49', 1); // CLASSICI SENZA TEMPO PER LEI
mapDefaultSortingCategories.set('65152a2957ad882b2177bab0', 1); // CLASSICI SENZA TEMPO PER LUI
mapDefaultSortingCategories.set('65144dfb57ad882b2177b948', 1); // REGALI PER LEI
mapDefaultSortingCategories.set('6514520457ad882b2177b955', 1); // REGALI PER LUI
mapDefaultSortingCategories.set('6515326b57ad882b2177baf4', 1); // SPORTY LUXE
mapDefaultSortingCategories.set('65158a9efc2e6b0ac0fd4378', 1); // Idee Ecosostenibili
mapDefaultSortingCategories.set('65158aadfc2e6b0ac0fd4379', 1); // Idee Ecosostenibili
mapDefaultSortingCategories.set('6516cc9232c2c703852fea58', 1); // IDEE REGALO NEONATA
mapDefaultSortingCategories.set('6516cebd7229bc1442778990', 1); // IDEE REGALO NEONATO
mapDefaultSortingCategories.set('651433f857ad882b2177b909', 1); // occasioni speciali bambino
mapDefaultSortingCategories.set('6514326357ad882b2177b905', 1); // OCCASIONI SPECIALI BAMBINA

export const categoryHasDefaultSorting = (category) => mapDefaultSortingCategories.has(category);
