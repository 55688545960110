<template>
  <div class="topbar" v-show="items.length > 0">
    <div class="topbar__items">
      <div
        v-for="(item, index) in items"
        :key="`topbar-item-${index}`"
        :class="`topbar__item ${index === 0 ? 'topbar__item--active' : ''}`"
      >
        <nuxt-link
          v-if="item.data.link.url"
          :to="fixUrlForCurrentLocale(`/${item.data.link.url}`)"
          :external="
            item.data.link.url ? item.data.link.url.includes('http') : false
          "
        >
          {{ item.data.message }}
        </nuxt-link>
        <div v-else>
          {{ item.data.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { SfCarousel, SfLink } from '@storefront-ui/vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'TopBar',
  components: {
    SfLink,
    SfCarousel,
  },
  props: {
    data: {
      type: Object | Array,
      default: () => ({}),
    },
  },
  setup(props) {
    let items =
      (Array.isArray(props.data) ? props.data : [props.data])?.filter((item) =>
        item.active === undefined ? true : item.active
      ) || [];

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    onMounted(() => {
      if (items.length <= 1) return;
      // get all elements with the class topbar__item inside the topbar component
      const topbarItems = document.querySelectorAll('.topbar__item');
      // get the first element with the class topbar__item--active inside the topbar component
      let activeItem = document.querySelector('.topbar__item--active');
      // get the index of the active item
      let activeItemIndex = [...topbarItems].indexOf(activeItem);
      // increase the active item every 5 seconds
      setInterval(() => {
        // remove the class topbar__item--active from the active item
        activeItem.classList.remove('topbar__item--active');
        // get the next item
        const nextItem = topbarItems[activeItemIndex + 1];
        // if the next item does not exist, get the first item
        const itemToActivate = nextItem || topbarItems[0];
        // add a transition class to the item for 500ms then remove it and activate the item
        itemToActivate.classList.add('topbar__item--activating');
        // add a timeout to remove the transition class and activate the item
        activeItem.classList.add('topbar__item--deactivating');
        setTimeout(() => {
          activeItem.classList.remove('topbar__item--deactivating');
          itemToActivate.classList.remove('topbar__item--activating');
          // add the class topbar__item--active to the item
          itemToActivate.classList.add('topbar__item--active');
          // update the active item
          activeItem = itemToActivate;
          // update the active item index
          activeItemIndex = [...topbarItems].indexOf(activeItem);
        }, 500);
      }, 5000);
    });

    return {
      items,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss">
.topbar {
  @include paragraph-regular;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  @media (max-width: 425px) {
    height: 2.25rem;
  }
  // z-index: 2;
  background: var(--c-black);
  color: var(--c-white);
  width: 100%;
  text-align: center;
  @media (max-width: 425px) {
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    font-family: var(--barlow-medium);
  }
  &__items {
    width: 100%;
    height: 1.875rem;
    position: relative;
    @media (max-width: 425px) {
      height: 2.25rem;
    }
  }
  &__item {
    position: absolute;
    top: 0.3125rem;
    @media (max-width: 425px) {
      top: 0.6875rem;
    }
    width: 100%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
    // add a transition effect
    // &--activating {
    // }
    // &--deactivating {
    //   transition: none;
    // }
    &--active {
      opacity: 1;
      z-index: 1;
      pointer-events: all;
    }
  }
  a {
    color: var(--c-white);
    text-decoration: none;
    display: inline-block;
    &:hover {
      color: var(--c-white);
      text-decoration: underline;
    }
  }
}
</style>
